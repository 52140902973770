import styles from './header.module.scss';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { Nav } from "./Nav";
import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect } from 'react';
import { GameStateContext } from '../../context/gameStateContext';

export function Header() {
    const ctx = useContext(GameStateContext);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (ctx.group._ === 'moderator') {
            if (location.pathname === '/') {
                navigate('/game/');
            }
        }
    }, [ctx.group._, location]);

    return (
        <header>
            <div className="container">
                <div className={styles.content}>
                    <a href="/" className={styles.logo}>
                        <Logo />
                    </a>
                    <Nav />
                </div>
            </div>
        </header>
    );
}