import React from "react";

import styles from './loader.module.scss';

export function Loader() {
    return (
        <div className={styles.wrapper}>
            <div className={styles.item}></div>
            <div className={styles.item}></div>
            <div className={styles.item}></div>
        </div>
    );
}