import { onValue, ref } from "firebase/database";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getGameState } from "../hooks/apiHooks";
import { db } from "../utils/firebase";
import { GameStateContext } from "./gameStateContext";

export const FireBaseContext = createContext();

export function FirebaseProvider({ children }) {
    const ctx = useContext(GameStateContext);
    const [fbInfo, setFbInfo] = useState({ state: '' })

    function initDB() {
        // debugger
        if (ctx.gameInfo._.id) {
            const query = ref(db, ctx.gameInfo._.id);
            return onValue(query, (snapshot) => {
                const data = snapshot.val()
                console.log(data, 'fireBase_data')
                setFbInfo(data)
            })
        }
    }

    useEffect(() => {
        initDB()
    }, [ctx.gameInfo._.id])

    useEffect(() => {
        console.log(fbInfo, 'fireBase_data__fbInfo')
        if(typeof fbInfo.time !== 'undefined'){
            ctx.timer.set(fbInfo.time)
            if(fbInfo.state !== ctx.gameState._.state){
                getGameState(ctx.gameState.set)
            }
        } else {
            ctx.timer.set(false)
            getGameState(ctx.gameState.set)
        }
    }, [fbInfo])

    return (
        <FireBaseContext.Provider value={fbInfo}>
            {children}
        </FireBaseContext.Provider>
    );
}