import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import { Title } from "../../../components/Title";
import { Choice } from "../../../components/Choice";
import { BackButton } from "../../../components/BackButton";
import { GameStateContext } from "../../../context/gameStateContext";
import { FireBaseContext } from "../../../context/firebaseContext";
import { getGameState, getQuestionsList, setApiPost } from "../../../hooks/apiHooks";
import { getCookie } from "../../../Helper";
import { Button } from "../../../components/Button";

import styles from './gamechoice.module.scss';
import { Text } from "../../../components/Text";

export function GameChoice() {
    const navigate = useNavigate();

    const ctx = useContext(GameStateContext);
    const fbCtx = useContext(FireBaseContext);

    const [canChoose, setCanChoose] = useState(false)
    const [actPlayer, setActPlayer] = useState('');

    useEffect(() => {
        if (ctx.gameState._.player_id) {
            ctx.playersList._.map(player => {
                if (ctx.gameState._.player_id === player.id) {
                    setActPlayer(player);
                }
            })
        }
    }, [ctx.gameState])

    useEffect(() => {
        getGameState(ctx.gameState.set)
        getQuestionsList(ctx.questionsList.set)
    }, [])

    useEffect(() => {
        // if (ctx.group._ === 'player') {
        let cookie = parseInt(getCookie('player_id'));
        // console.log(ctx.gameState._.player_id, cookie, ctx.gameState._.player_id == cookie)
        if (ctx.gameState._.player_id == cookie || ctx.group._ === 'moderator') {
            // debugger
            setCanChoose(true)
        }
        // }
    }, [ctx.gameState])

    // useEffect(() => {
    //     getGameState(ctx.gameState.set);
    // }, [fbCtx]);

    // useEffect(() => {
    //     if (ctx.gameState._.state === 'choose') {
    //         navigate('/game/question/')
    //     }
    // }, [ctx.gameState])

    function onChoose(question_id) {
        console.log('CLICKED')
        if (ctx.group._ === 'player' || ctx.group._ === 'moderator') {
            let cookie = parseInt(getCookie('player_id'));
            if (ctx.gameState._.player_id == cookie || ctx.group._ === 'moderator') {
                setApiPost('/games/choose/', {
                    player_id: ctx.gameState._.player_id,
                    question_id: question_id,
                }, console.log)
            }
        }
    }

    function goBack() {
        navigate('/game/draw');
    }

    function handleClick() {
        if (ctx.group._ === 'moderator') {
            setApiPost('/questions/skip/')
        }
    }

    return (
        <Card type={'center'}>
            {/* {ctx.group._ === 'moderator' && */}
            {/* // <BackButton onClick={goBack} /> */}
            {/* // } */}
            <Title margin={24}>
                Выберите тему и стоимость вопроса
            </Title>
            <Text size={'p2'} margin={24}>
                Сейчас ходит: {actPlayer.name}
            </Text>
            <Choice list={ctx.questionsList._} onChoose={onChoose} canChoose={canChoose} />
            {ctx.group._ === 'moderator' &&
                <div className={styles.btnWrapper}>
                    <Button onClick={handleClick}>Пропустить вопрос</Button>
                </div>
            }
        </Card>
    );
}