import React, { useContext, useEffect, useState } from "react";

import { Card } from "../Card";
import { Title } from "../Title";
import { Button } from "../Button";
import { Text } from "../Text";
import { QuizItem } from "./QuizItem";

import './quizform.scss';
import { GameStateContext } from "../../context/gameStateContext";
import { getPlayersList } from "../../hooks/apiHooks";
import { FireBaseContext } from "../../context/firebaseContext";
import { deleteCookie, getCookie, setCookie } from "../../Helper";
import { useNavigate } from "react-router";

let answers = {};

export function QuizForm(props) {
    const ctx = useContext(GameStateContext)
    const fbCtx = useContext(FireBaseContext);
    const navigate = useNavigate();

    // function onSubmit() {
    //     let firstValue, secondValue;

    //     firstValue = itemsFirst.filter(el => el.id === isActiveFirst)[0].value
    //     secondValue = itemsSecond.filter(el => el.id === isActiveSecond)[0].value

    //     props.onSubmit(
    //         firstValue,
    //         secondValue
    //     )
    // }
    useEffect(() => {
        getPlayersList(ctx.playersList.set);
        
    }, [fbCtx])

    useEffect(() => {
        if(ctx.group._ === 'moderator'){
            let answered = false
            ctx.playersList._.map(player => {
                if(!player.quiz){
                    answered = true
                }
            })
            
            if(!answered){
                navigate('/game/result/')
            }
        }
    }, [ctx.playersList._])

    return (
        <Card type={'center'}>
            <div className={'row'}>
                {ctx.group._ === 'moderator' &&
                    <div className={'bar'}>
                        {ctx.playersList._.map(player => {
                            return (
                                <div className="player">{player.name}:<span className="divider"></span> <span class={"status " + (player.quiz ? "__sended" : "")}>{player.quiz ? 'Ответил' : "Не ответил"}</span></div>
                            )
                        })}
                    </div>
                }
                <div className={'list'}>
                    {ctx.playersList._.map(player => {
                        if (ctx.group._ === 'moderator') {

                            if (!player.quiz) {
                                return (
                                    <div className="player">
                                        <div className="player__name">Игрок: {player.name}</div>
                                        <CardItem player={player} onSubmit={props.onSubmit} />
                                    </div>
                                )
                            }
                        } else {
                            if (parseInt(player.id) === parseInt(getCookie('player_id'))) {
                                return <CardItem player={player} onSubmit={props.onSubmit} isAnswered={player.quiz} />
                            }
                        }
                    })}
                </div>
            </div>
        </Card>
    );
}

function CardItem(props) {
    const [isActiveFirst, setActiveFirst] = useState(false);
    const [isActiveSecond, setActiveSecond] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [isAnswered, setAnswered] = useState(false);
    const navigate = useNavigate();


    const setData = (item, type) => {
        if (type === 1) {
            setActiveFirst(item.id);
        } else {
            setActiveSecond(item.id);
        }
    }

    useEffect(() => {
        if (props.isAnswered) {
            setAnswered(true)
            setCookie('quiz_sended', true)
            deleteCookie('player_id')
            navigate('/game/result/')
        }
    }, [props.isAnswered])

    useEffect(() => {
        if (isActiveFirst && isActiveSecond) {
            setDisabled(false)
        }
    }, [isActiveFirst, isActiveSecond])

    const itemsFirst = [
        { id: '1', value: '1' },
        { id: '2', value: '2' },
        { id: '3', value: '3' },
        { id: '4', value: '4' },
        { id: '5', value: '5' },
        { id: '6', value: '6' },
        { id: '7', value: '7' },
        { id: '8', value: '8' },
        { id: '9', value: '9' },
        { id: '10', value: '10' }
    ];

    const itemsSecond = [
        { id: '11', value: '1' },
        { id: '12', value: '2' },
        { id: '13', value: '3' },
        { id: '14', value: '4' },
        { id: '15', value: '5' },
        { id: '16', value: '6' },
        { id: '17', value: '7' },
        { id: '18', value: '8' },
        { id: '19', value: '9' },
        { id: '20', value: '10' }
    ];

    function onSubmit() {
        let id = props.player.id;
        let firstValue = itemsFirst.filter(el => el.id === isActiveFirst)[0].value;
        let secondValue = itemsSecond.filter(el => el.id === isActiveSecond)[0].value;

        props.onSubmit(id, firstValue, secondValue)

        setAnswered(true)
    }

    return (
        <>
            <div className={`item ${isAnswered && 'disabled'}`}>
                <Title As={'h2'} margin={32} color={'black'}>
                    Оцените свои экологические знания до игры
                </Title>
                <ul className={'list'}>
                    {itemsFirst.map(item =>
                        <QuizItem key={item.id} id={item.id} name="1" active={isActiveFirst === item.id} handleClick={() => setData(item, 1)}>
                            {item.value}
                        </QuizItem>
                    )}
                </ul>
                <Text size={'p1'} color={'black'}>
                    Где 1 - очень плохо, 10 - очень хорошо
                </Text>
            </div>
            <div className={`item ${isAnswered && 'disabled'}`}>
                <Title As={'h2'} margin={32} color={'black'}>
                    Оцените свои экологические знания после игры
                </Title>
                <ul className={'list'}>
                    {itemsSecond.map(item =>
                        <QuizItem key={item.id} id={item.id} name="2" active={isActiveSecond === item.id} handleClick={() => setData(item, 2)}>
                            {item.value}
                        </QuizItem>
                    )}
                </ul>
                <Text size={'p1'} color={'black'}>
                    Где 1 - очень плохо, 10 - очень хорошо
                </Text>
            </div>
            {
                isAnswered ?
                    (
                        <Title>
                            Спасибо за участие в игре!
                        </Title>
                    ) : (
                        <div className={'btn'}>
                            <Button isDisabled={isDisabled} onClick={onSubmit}>
                                Отправить
                            </Button>
                        </div>
                    )
            }
        </>
    )
}