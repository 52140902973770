import { useNavigate } from "react-router-dom";

import { Card } from '../../components/Card';
import { Title } from '../../components/Title';
import { Button } from '../../components/Button';
import { RulesList } from '../../components/RulesList';
import { useContext } from "react";
import { GameStateContext } from "../../context/gameStateContext";

export function Rules() {
    const navigate = useNavigate();
    const ctx = useContext(GameStateContext)
    return (
        <Card type={"left"}>
            <Title margin={32}>
                Правила для участников
            </Title>
            <RulesList rules={ctx.gameInfo._.rules} />
            <Button onClick={() => navigate('/game/')}>
                Принять
            </Button>
        </Card>
    );
}