
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"
import { createContext } from "react";

const FirebaseContext = createContext(null);
export { FirebaseContext }

// const firebaseConfig = {
//   apiKey: "AIzaSyCj-B2rX9sZnuCTytDTIFmNQq9QPlMWaMk",
//   authDomain: "ecorevizzorro-581c0.firebaseapp.com",
//   databaseURL: "https://ecorevizzorro-581c0-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "ecorevizzorro-581c0",
//   storageBucket: "ecorevizzorro-581c0.appspot.com",
//   messagingSenderId: "697940289429",
//   appId: "1:697940289429:web:e48d4de09662a7bd8c389c",
//   measurementId: "G-CSEKN1WS7J"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDXvmNWkkmOiDeqAhdUGZfCkpsBHxBCIIo",
  authDomain: "eco3-3e2ee.firebaseapp.com",
  databaseURL: "https://eco3-3e2ee-default-rtdb.firebaseio.com",
  projectId: "eco3-3e2ee",
  storageBucket: "eco3-3e2ee.appspot.com",
  messagingSenderId: "424115029044",
  appId: "1:424115029044:web:cba396596ec94eb268f661",
  measurementId: "G-F4FK6M8LTD"
};



export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
