import { useState, useEffect } from "react";

export function TimerFunction(date, result, timerCheckStop = () => { return false }) {
    const [time, setTime] = useState({ hours: null, minutes: null, seconds: null, ended: false });
    useEffect(() => {
        if (!isNaN(date.getTime())) {
            const timer = setInterval(() => {
                var now = new Date();
                var currentDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
                const curDate = new Date(currentDate.toISOString())
                // debugger
                const endingTime = date - currentDate;
                // debugger
                if (time.ended) {
                    return
                }
                if (timerCheckStop()) {
                    return
                }
                console.log(date, currentDate, date - currentDate, endingTime, 'as')
                if (endingTime < 1) {
                    setTime({ hours: '00', minutes: '00', seconds: '00', ended: true });
                    console.log('timer')
                    clearInterval(timer);
                    result();

                    return false
                } else {
                    const hours = Math.floor((endingTime / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
                    const minutes = Math.floor((endingTime / 1000 / 60) % 60).toString().padStart(2, '0');
                    const seconds = Math.floor((endingTime / 1000) % 60).toString().padStart(2, '0');

                    setTime({ hours, minutes, seconds, ended: false });
                }
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [date, time]);

    return time;
}

export function setCookie(name, value, options = {}) {
    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        // eslint-disable-next-line no-useless-escape
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name) {
    setCookie(name, "", {
        'max-age': -1
    })
}

export function getRawHTML(data) {
    return { __html: data };
}

export function randomizer(targetId, list, setChosen, setBtnDisabled, setEnded) {
    setBtnDisabled(true);
    let currentRand = 0;
    let counter = 0;

    // debugger

    if (list.length === 1) {
        // clearInterval(interval);
        setChosen(targetId.toString());

        setTimeout(() => {
            setEnded(true);
        }, 1000);
        return
    }

    let interval = setInterval(() => {
        const randomElement = list[Math.floor(Math.random() * list.length)];
        const rand = randomElement.id;

        if (rand !== currentRand) {
            setChosen(rand.toString());
            currentRand = rand;
            counter++;
        }

        if (counter > 9) {
            clearInterval(interval);
            setChosen(targetId.toString());

            setTimeout(() => {
                setEnded(true);
            }, 1000);
        }
    }, 100);
}