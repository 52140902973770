import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { TimerFunction } from "../../Helper";

import { Text } from "../Text";
import { Title } from "../Title";

import styles from './timer.module.scss';

export function Timer({ type, date, result = () => { }, fullHeight = false, isAnswered = false }) {
    const [isEnded, setEnded] = useState(false)
    const [isEndStop, setEndStop] = useState(false)
    const classes = classNames(
        styles.outer,
        styles[type],
        { [styles.height]: fullHeight }
    );
    // let _date = new Date(date)

    var now = new Date(date);
    var currentDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours() - 3, now.getUTCMinutes(), now.getUTCSeconds());
    const time = TimerFunction(currentDate, _result, checkStop);
    let endTime = '';

    function checkStop() {
        return isAnswered
    }

    function _result() {
        if (!isEndStop) {
            // console.log("RESULT MAAFAKA")
            result()
            setEnded(true)
        }
    }

    return (
        <div className={classes}>
            <div className={styles.inner}>
                {
                    type === 'start' && (
                        <>
                            {isEnded ?
                                <Text size={'p1'} weight={'regular'} color={'white'} margin={24}>
                                    Игра вот вот начнется
                                </Text>
                                :
                                <Text size={'p1'} weight={'regular'} color={'white'} margin={24}>
                                    Игра начнется<br /> через
                                </Text>
                            }
                            {
                                time.seconds !== null && (
                                    <Title As={'h2'} weight={'bold'} color={'white'}>
                                        {time.hours}:{time.minutes}:{time.seconds}
                                    </Title>
                                )
                            }
                        </>
                    )
                }
                {
                    type === 'question' && (
                        <>
                            <Text size={'p1'} weight={'semibold'} color={'black'} margin={24}>
                                Время на выбор ответа
                            </Text>
                            {
                                time.seconds !== null && (
                                    <Title As={'h2'} weight={'bold'} color={'orange'}>
                                        {time.minutes}:{time.seconds}
                                    </Title>
                                )
                            }
                        </>
                    )
                }
            </div>
        </div>
    );
}