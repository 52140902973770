import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card } from "../../../components/Card";
import { Title } from "../../../components/Title";
import { Rating } from "../../../components/Rating";
import { Button } from "../../../components/Button";

import styles from './gameresult.module.scss';
import { GameStateContext } from "../../../context/gameStateContext";
import { getPlayersList } from "../../../hooks/apiHooks";
import { getCookie } from "../../../Helper";
import { FireBaseContext } from "../../../context/firebaseContext";

export function GameResult() {
    const navigate = useNavigate();

    const ctx = useContext(GameStateContext)
    const fbCtx = useContext(FireBaseContext);
    const [answered, setAnswered] = useState(false)

    // useEffect(() => {
    //     getPlayersList(ctx.playersList.set)
    // }, [])

    useEffect(() => {
        getPlayersList(ctx.playersList.set)
    }, [fbCtx])

    useEffect(() => {
        if(ctx.group._ === 'moderator'){

            let answered = false
            ctx.playersList._.map(player => {
                if (!player.quiz) {
                    answered = true
                }
            })
            
            // console.log('answered', answered)
            setAnswered(answered)
        }
    }, [ctx.playersList._])

    return (
        <Card type={'center'}>
            <Title margin={32}>
                Игра завершена
            </Title>
            <Rating type={'result'} teams={ctx.playersList._} />
            {((getCookie('player_id') && !getCookie('quiz_result')) || ((ctx.group._ === 'moderator') && answered)) &&
                <div className={styles.finalBtn}>
                    <Button onClick={() => navigate('/game/quiz/')}>
                        Далее
                    </Button>
                </div>
            }
        </Card>
    );
}