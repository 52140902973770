import React from "react";

import { Text } from "../../Text";

import styles from './questions.module.scss';

export function Questions({ themes, onChoose, canChoose }) {
    return (
        <ul className={styles.wrapper}>
            {themes.map((item, index) =>
                <li key={index}>
                    {
                        item.items.map(qst =>
                            <div className={styles.points + " " + (canChoose ? "" : "noActivity") + " " + (qst.status === 'disable' ? "disabled" : "")}
                                value={200}
                                onClick={(canChoose && qst.status !== 'disable') ? () => onChoose(qst.question_id) : () => { }}
                            >
                                <Text size={'p1'} weight={'regular'} color={'white'}>
                                    {qst.score}
                                </Text>
                            </div>
                        )
                    }
                </li>
            )}
        </ul>
    );
}