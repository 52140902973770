import classNames from "classnames";
import React from "react";

import { ReactComponent as Star } from '../../assets/icons/star_big.svg';
import { Text } from "../Text";

import styles from './rating.module.scss';

export function Rating(props) {
    const classes = classNames(
        styles.list,
        styles[props.type]
    );

    // debugger

    let i = 0;
    return (
        <ul className={classes}>
            {props.teams.map((item) => {
                i++;
                return (
                    <li className={styles.item} key={i}>
                        <div className={styles.place}>
                            <Star />
                            <span>{i}</span>
                        </div>
                        <div className={styles.name}>
                            <Text size={'p1'} weight={'regular'} color={'black'}>
                                {item.name}
                            </Text>
                        </div>
                        {
                            props.type === 'glory' && (
                                <div className={styles.info}>
                                    <Text size={'p1'} weight={'regular'} color={'gray'}>
                                        {item.city}
                                    </Text>
                                    <Text size={'p1'} weight={'regular'} color={'gray'}>
                                        {item.school}
                                    </Text>
                                </div>
                            )
                        }
                        <div className={styles.points}>
                            <Text size={'p1'} weight={'semibold'} color={'green'}>
                                {item.score}
                            </Text>
                        </div>
                    </li>
                )
            }
            )}
        </ul>
    );
}