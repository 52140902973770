import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import { Card } from '../../components/Card';
import { Text } from '../../components/Text';
import { Timer } from '../../components/Timer';
import { Title } from '../../components/Title';
import { Form } from '../../components/Form';
import { Loader } from '../../components/Loader';
import { AwaitingList } from '../../components/AwaitingList';

import styles from './game.module.scss';
import { GameStateContext } from '../../context/gameStateContext';
import { getCookie, setCookie } from '../../Helper';
import { getGameState, getPlayersList, setApiPost, setGameStart } from '../../hooks/apiHooks';
import { FireBaseContext } from '../../context/firebaseContext';
import { Button } from '../../components/Button';

export function Game() {
    const ctx = useContext(GameStateContext);
    const fbCtx = useContext(FireBaseContext)
    const navigate = useNavigate();

    const [id, setId] = useState('');
    const [currentStep, setCurrentStep] = useState('reg');
    const [btnActive, setBtnActive] = useState(false)

    const result = () => {
        let cookie = getCookie('player_id');
        console.log("RESULT")
        setBtnActive(true)

        if (cookie) {
            // navigate('/game/draw/');
        } else {
            // if (ctx.gameState._.state !== 'init') {
            //     navigate('/');
            // }
        }
    }

    const formSuccess = (payload) => {
        if (payload.player_id) {
            setCookie('player_id', payload.player_id);
            setCurrentStep('send');
        }
    }

    const onSubmit = (formRef, setNameError, setCityError, setSchoolError, setEmailError) => {
        let formData = new FormData(formRef.current);
        let json = JSON.parse(JSON.stringify(Object.fromEntries(formData)));

        Object.keys(json).map((key) => {
            console.log(key)
            if (key === 'exist') {
                if (json[key] === 'on') {
                    json[key] = true;
                } else {
                    json[key] = false;
                }
            }
        });

        setApiPost('/players/create/', json, (payload) => {
            setNameError(false);
            setCityError(false);
            setSchoolError(false);
            setEmailError(false);
            // debugger
            if (payload.player_id) {
                setCookie('player_id', payload.player_id);
                setCurrentStep('send');
                getGameState(ctx.gameState.set)
            } else {
                // navigate('/')
                window.location.reload()
            }
        }, (res) => {
            setNameError(false);
            setCityError(false);
            setSchoolError(false);
            setEmailError(false);

            Object.keys(res.data.payload.error).map(key => {
                if (key === 'school_name') {
                    setSchoolError(res.data.payload.error[key]);
                }
                if (key === 'city_name') {
                    setCityError(res.data.payload.error[key]);
                }
                if (key === 'name') {
                    setNameError(res.data.payload.error[key]);
                }
                if (key === 'email') {
                    setEmailError(res.data.payload.error[key]);
                }
            });
        })
    }

    useEffect(() => {
        let cookie = getCookie('player_id');
        if (cookie && ctx.group._ === 'player') {
            setCurrentStep('send')
        }
        console.log('ctx.playersList._.length', ctx.playersList._.length)
    }, [])
    useEffect(() => {
        console.log("SET PLAYERS LIST 1")
        if (ctx.group._ === 'moderator') {
            console.log("SET PLAYERS LIST 2")
            if (ctx.gameState._.state === 'registration') {
                getPlayersList((res) => {
                    // debugger
                    if (res.length !== ctx.playersList._.length) {
                        ctx.playersList.set(res)
                    }
                })
                console.log("SET PLAYERS LIST 3")
            }
        }
    }, [fbCtx, ctx])

    function confirmStart() {
        setGameStart()
    }

    return (
        <>
            <Card type={'left'}>
                {ctx.group._ === 'player' ? (
                    currentStep === 'reg' ? (
                        <>
                            <Title margin={32}>
                                Экоревизорро!
                            </Title>
                            <Form classname={styles.form} onSubmit={onSubmit} />
                            <Timer type={'start'} date={ctx.gameInfo._.date} result={result} />
                        </>
                    ) : (
                        <>
                            <Text size={'p1'} weight={'semibold'} color={'black'}>
                                Данные отправлены! Ожидайте<br /> начала игры!
                            </Text>
                            <Timer type={'start'} date={ctx.gameInfo._.date} result={result} fullHeight={true} />
                        </>
                    )
                ) : (
                    <>
                        <Title margin={32}>
                            Список<br />
                            участников
                        </Title>
                        {ctx.gameState._.state !== 'finish' ?
                            <>
                                <AwaitingList players={ctx.playersList._} />
                                <Loader />
                                <>
                                    <Button isDisabled={ctx.playersList._.length ? !btnActive : true} onClick={confirmStart}>
                                        Начать
                                    </Button>
                                </>
                                <Timer type={'start'} date={ctx.gameInfo._.date} result={result} />
                            </>
                            :
                            <Text size={'p1'} weight={'semibold'} color={'black'}>
                                Нет активной игры
                            </Text>
                        }
                    </>
                )
                }
            </Card>
        </>
    );
}