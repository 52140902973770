import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Header } from "./components/Header";
import { Main } from "./screens/Main";
import { Rules } from "./screens/Rules";
import { Game } from "./screens/Game";
import { GameDraw } from "./screens/Game/GameDraw";
import { GameChoice } from "./screens/Game/GameChoice";
import { GameQuestion } from "./screens/Game/GameQuestion";
import { GameResult } from "./screens/Game/GameResult";
import { GameQuiz } from "./screens/Game/GameQuiz";
import { HallOfGlory } from "./screens/HallOfGlory";
import { Picture } from "./components/Picture";

import './css/main.global.scss';

import Background from './assets/images/bg.png';
import BackgroundSmall from './assets/images/bg_small.png';
import { FirebaseProvider } from "./context/firebaseContext";
import { GameStateContext, GameStateContextProvider } from "./context/gameStateContext";
import { API_Setter } from "./components/api_setter";
import { getServerTime } from "./hooks/apiHooks";
import API from "./API";

export default function App() {
    useEffect(() => {
        console.log('_BASE_URL_', process.env.REACT_APP_BASE_URL)
    })
    return (
        <Router>
            <GameStateContextProvider>
                <FirebaseProvider>
                    <API_Setter />
                    <Header />
                    {/* <__TIME_CHECK__ /> */}
                    <main>
                        <Routes>
                            <Route path='/'>
                                <Route index element={<Main />} />
                                <Route path='rules' element={<Rules />} />
                                <Route path='game'>

                                    <Route index element={<Game />} />
                                    <Route path="draw" element={<GameDraw />} />
                                    <Route path="choice" element={<GameChoice />} />
                                    <Route path="question" element={<GameQuestion />} />
                                    <Route path="result" element={<GameResult />} />
                                    <Route path="quiz" element={<GameQuiz />} />

                                </Route>
                                <Route path='hallofglory' element={<HallOfGlory />} />
                            </Route>
                        </Routes>
                        {/* <Picture src={Background} srcset={BackgroundSmall} /> */}
                    </main>
                </FirebaseProvider>
            </GameStateContextProvider>
        </Router>
    );
}

function __TIME_CHECK__() {
    const [time, setTime] = useState({
        localTime: '',
        localTimeUTC: '',
        localTimeOffset: '',
        serverTime: '',
        serverTimeUTC: '',
        serverTimeOffset: '',
    })
    useEffect(() => {
        setTimer(setTime)
    }, [])
    return (
        <div style={{ position: 'fixed', left: 0, bottom: 0, background: 'white', padding: '4px 10px', zIndex: 20, fontSize: '10px', boxShadow: '0 0 2px black' }}>
            <div>{`Локальное время: ${time.localTime} | UTC: ${time.localTimeUTC} | Offset: ${time.localTimeOffset}`}</div>
            <div>{`Серверное время: ${time.serverTime} | UTC: ${time.serverTimeUTC} | Offset: ${time.serverTimeOffset}`}</div>
        </div>
    )
}

function setTimer(setTime) {
    console.log('time SERVER')
    let interval = setInterval(() => {
        API.get('/server/time/').then(res => {
            if (res.data.status === 'success') {
                getTime(res.data.payload.data)
            }
        }).catch(console.log)
    }, 1000)

    function getTime(date) {

        const serverOffset = 3;
        // Получение текущего времени по Гринвичу
        const local = new Date();
        const localUtcTime = local.getTime() + (local.getTimezoneOffset() * 60000);
        const localTimeGMT = new Date(localUtcTime);

        // let _serverDate = '2023-04-25T16:44:00+03:00';
        let _serverDate = date;

        const server = new Date(_serverDate);

        const serverUTCTime = server.getTime() + ((server.getTimezoneOffset()) * 60000);
        console.log(date, server.getTimezoneOffset() - 180, server.getTimezoneOffset())
        const serverTimeGMT = new Date(serverUTCTime);
        let serverTimeMock = new Date(serverUTCTime);
        serverTimeMock.setHours(serverTimeMock.getHours() + serverOffset)

        // Получение текущего времени на устройстве
        const localTime = new Date();

        // Вычисление смещения часового пояса в часах
        const localOffset = (localTime.getTimezoneOffset() / 60) * -1;

        // Вывод результатов
        // console.log("Текущее время по Гринвичу: " + gmtTime.toLocaleString());
        // console.log("Текущее местное время: " + localTime.toLocaleString());
        // console.log("Смещение часового пояса в часах: " + offset);
        // setLocalDate(gmtTime.toLocaleString())
        // setServerDate(getFormattedTime(serverDate))

        setTime({
            localTime: localTime.toLocaleString(),
            localTimeUTC: localTimeGMT.toLocaleString(),
            localTimeOffset: localOffset,
            serverTime: serverTimeMock.toLocaleString(),
            serverTimeUTC: serverTimeGMT.toLocaleString(),
            serverTimeOffset: serverOffset,
        })
    }
}
// function setTimer(setLocalDate, setServerDate) {
//     console.log('time SERVER')
//     let interval = setInterval(() => {
//         API.get('/server/time/').then(res => {
//             if (res.data.status === 'success') {
//                 getTime(res.data.payload.data * 1000)
//             }
//         }).catch(console.log)
//     }, 1000)

//     function getTime(date) {
//         var local = new Date();
//         var server = new Date(date);
//         let localDate = getTimeGrinvich(local, false)
//         let serverDate = getTimeGrinvich(server, true);

//         console.log(localDate.getTime(), serverDate.getTime(), 'REQUEST GET SERVER TIME')

//         setLocalDate(getFormattedTime(localDate))
//         setServerDate(getFormattedTime(serverDate))
//     }



//     function getTimeGrinvich(now, isServer = false) {
//         let offset = now.getTimezoneOffset();
//         var currentDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours() + (isServer ? 11 : 0), now.getUTCMinutes() + (isServer ? 0 : offset), now.getUTCSeconds());
//         return currentDate
//     }
// }

function getFormattedTime(time) {
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
    const minutes = Math.floor((time / 1000 / 60) % 60).toString().padStart(2, '0');
    const seconds = Math.floor((time / 1000) % 60).toString().padStart(2, '0');

    return hours + ':' + minutes + ":" + seconds
}