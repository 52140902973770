import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FireBaseContext } from "../../../context/firebaseContext";
import { GameStateContext } from "../../../context/gameStateContext";
import { getGameState, getPlayersDraw, getPlayersList, getQuestionsInfo, setApiPost, setQuestionResult } from "../../../hooks/apiHooks";

import { GameField } from "../../../components/GameField/index"
import { getCookie } from "../../../Helper";

export function GameQuestion() {
    const navigate = useNavigate();
    const ctx = useContext(GameStateContext);
    const fbCtx = useContext(FireBaseContext);
    const [correctAnswer, setCorrectAnswer] = useState(null)
    const [isAnswered, setAnswered] = useState(false)
    const [chosenId, setChosenId] = useState(null);
    const [isActivePlayer, setActivePlayer] = useState(false);
    const [isSkipped, setSkipped] = useState(false);
    const [actPlayer, setActPlayer] = useState('');


    useEffect(() => {
        setSkipped(false)
        getGameState(ctx.gameState.set);
        getQuestionsInfo(ctx.question.set);
        getPlayersList(ctx.playersList.set);
    }, [])

    useEffect(() => {
        console.log('123')
        if (fbCtx.state === 'answer' || fbCtx.state === 'wrong_answer') {
            setAnswered(true)
            // getGameState(ctx.gameState.set);
        }
        // if(fbCtx.state === 'lobby' || fbCtx.state === 'play'){
        //     // getGameState(ctx.gameState.set);
        // }
    }, [fbCtx])

    useEffect(() => {
        let cookie = getCookie('player_id')
        if (ctx.gameState._.player_id == cookie || ctx.group._ === 'moderator') {
            setActivePlayer(true)
        } else {
            setActivePlayer(false)
        }

        if(ctx.gameState._.state === 'answer' || ctx.gameState._.state === 'wrong_answer'){
            if (isAnswered) {
                console.log("CHECK")
                setCorrectAnswer(ctx.gameState._.result === 'true' ? true : false);
                setChosenId(ctx.gameState._.answer_id)
            }
        }
        if(ctx.gameState._.player_id){
            ctx.playersList._.map(player => {
                if(ctx.gameState._.player_id === player.id){
                    setActPlayer(player);
                }
            })
        }
        // debugger
        // if(ctx.gameState._.state === 'lobby'){
        //     navigate('/game/draw')
        // }
        // if(ctx.gameState._.state === 'play'){
        //     navigate('/game/choice/')
        // }
    }, [ctx.gameState])

    // debugger

    // let date = new Date()
    // date.setMinutes(date.getMinutes() + 1)
    // date = date.getTime();

    // console.log(ctx.question._)

    function checkAnswer(id) {
        // debugger
        if (isActivePlayer) {
            // debugger
            setApiPost('/questions/answer/', {
                question_id: ctx.question._.id,
                player_id: ctx.gameState._.player_id,
                answer_id: id,
                time: ctx.timer._
            })
        }
    }

    function handleClick() {
        if (isActivePlayer ) {
            if(ctx.timer._ === 0){
                setApiPost('/questions/skip/')
            } else {
                setQuestionResult()
            }
            // if(isAnswered){
                
            // } 
            // else {
            //     setSkipped(true)
            //     setApiPost('/questions/skip/')
            // }
        }
        // if(ctx.group._ === 'moderator'){
        //     setApiPost('/questions/skip/')
        // }
    }

    function skipAnswer(){
        if(ctx.group._ === 'moderator'){
            setApiPost('/questions/skip/')
        }
    }

    return (
        ctx.question._.answers ?
            <GameField
                isActivePlayer={isActivePlayer}
                isSkipped={isSkipped}
                question={ctx.question._}
                handleClick={handleClick}
                teams={ctx.playersList._}
                date={ctx.question._.date_time}
                chosenId={chosenId}
                setChosenId={setChosenId}
                correctAnswer={correctAnswer}
                checkAnswer={checkAnswer}
                isModerator={ctx.group._ === 'moderator'}
                skipAnswer={skipAnswer}
                timer={ctx.timer}
                actPlayer={actPlayer}
            />
            :
            <></>
    );
}