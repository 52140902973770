import React, { useEffect, useState } from "react";

import { Input } from '../Input';
import { Button } from "../Button";

export function Form({ classname, onSubmit = () => { } }) {
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [school, setSchool] = useState('');
    const [email, setEmail] = useState('');
    const [isNameError, setNameError] = useState(false);
    const [isCityError, setCityError] = useState(false);
    const [isSchoolError, setSchoolError] = useState(false);
    const [isEmailError, setEmailError] = useState(false);
    const [btnIsDisabled, setBtnIsDisabled] = useState(false);

    const formRef = React.createRef();

    useEffect(() => {
        const validate = () => {
            return name && city && school && email;
        }

        setBtnIsDisabled(validate);
    }, [city, email, school, name]);

    const _onSubmit = (e) => {
        e.preventDefault();
        onSubmit(formRef, setNameError, setCityError, setSchoolError, setEmailError);
    }

    return (
        <form className={classname} onSubmit={_onSubmit} ref={formRef}>
            <Input type={'text'} name={'name'} label={'Название команды'} placeholder={'например, «Умники и умницы»'} onChange={(e) => setName(e.target.value)} isError={isNameError} />
            <Input type={'text'} name={'city_name'} label={'Город'} placeholder={'например, Москва'} onChange={(e) => setCity(e.target.value)} isError={isCityError} />
            <Input type={'text'} name={'school_name'} label={'Учебное заведение'} onChange={(e) => setSchool(e.target.value)} isError={isSchoolError} />
            <Input type={'text'} name={'email'} label={'Email'} placeholder={'_@_'} onChange={(e) => setEmail(e.target.value)} isError={isEmailError} />
            <Input type={'checkbox'} name={'exist'} label={'Уже регистрировались'} />
            <Button isDisabled={false}>
                Зарегистрироваться
            </Button>
        </form>
    );
}