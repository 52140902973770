import React from "react";
import classNames from "classnames";

import styles from './bars.module.scss';

export function Bars({ direction = 'row', margin, children }) {
    const classes = classNames(
        styles.list,
        styles[direction],
        styles[`margin-${margin}`]
    );

    return (
        <ul className={classes}>
            {children}
        </ul>
    );
}