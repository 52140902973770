import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteCookie, getCookie } from "../Helper";

export const GameStateContext = createContext();

export function GameStateContextProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [gameState, setGameState] = useState({ state: false });
    const [group, setGroup] = useState(getCookie('GAME_LOGIN') ? "moderator" : "player");
    const [playerId, setPlayerId] = useState('');
    const [gameInfo, setGameInfo] = useState('');
    const [playersList, setPlayersList] = useState([]);
    const [playersDraw, setPlayersDraw] = useState({});
    const [questionsList, setQuestionsList] = useState([]);
    const [question, setQuestion] = useState({});
    const [playersTop, setPlayersTop] = useState([]);
    const [timer, setTimer] = useState(0);

    const ctx = {
        gameState: {
            _: gameState,
            set: setGameState,
        },
        group: {
            _: group,
            set: setGroup,
        },
        playerId: {
            _: playerId,
            set: setPlayerId,
        },
        playersList: {
            _: playersList,
            set: setPlayersList,
        },
        playersDraw: {
            _: playersDraw,
            set: setPlayersDraw,
        },
        playersTop: {
            _: playersTop,
            set: setPlayersTop,
        },
        gameInfo: {
            _: gameInfo,
            set: setGameInfo,
        },
        questionsList: {
            _: questionsList,
            set: setQuestionsList,
        },
        question: {
            _: question,
            set: setQuestion,
        },
        timer: {
            _: timer,
            set: setTimer,
        }
    }

    useEffect(() => {
        let cookie = getCookie('player_id')

        if (!cookie && ctx.group._ === 'player') {
            navigate('/')
            return
        }
    }, [])

    useEffect(() => {
        console.log(ctx.gameState._ ? ctx.gameState._.state : 'undefined', ctx, location)
        let cookie = getCookie('player_id')
        if (!ctx.group._ === 'moderator' && !cookie) {
            if (location.pathname !== '/') {
                navigate('/')
            }
            return
        }

        if (cookie || ctx.group._ === 'moderator') {

            if (
                location.pathname !== '/game/quiz/' &&
                location.pathname !== '/hallofglory/'
            ) {
                if (ctx.gameState._.state === 'draw') {
                    if (location.pathname !== '/game/draw/') {
                        navigate('/game/draw/')
                    }
                }
                if (ctx.gameState._.state === 'lobby') {
                    if (location.pathname !== '/game/draw/') {
                        navigate('/game/draw/')
                    }
                }
                if (ctx.gameState._.state === 'play') {
                    if (location.pathname !== '/game/choice/') {
                        navigate('/game/choice/')
                    }
                }
                if (ctx.gameState._.state === 'choose') {
                    if (location.pathname !== '/game/question/') {
                        navigate('/game/question/')
                    }
                }
                if (ctx.gameState._.state === 'end') {
                    if (location.pathname !== '/game/result/') {
                        navigate('/game/result/')
                    }
                }
                if (ctx.group._ === 'moderator') {
                    if (ctx.gameState._.state === 'init') {
                        if (location.pathname !== '/game/') {
                            navigate('/game/')
                        }
                    }
                    if (ctx.gameState._.state === 'registration') {
                        if (location.pathname !== '/game/') {
                            navigate('/game/')
                        }
                    }
                    if (ctx.gameState._.state === 'finish') {
                        if (location.pathname !== '/game/') {
                            navigate('/game/')
                        }
                    }
                }
                if (ctx.group._ === 'player') {
                    if (ctx.gameState._.state === 'end') {
                        if (location.pathname !== '/game/') {
                            if (getCookie('quiz_sended')) {
                                // navigate('/')
                            }
                        }
                    }
                    if (ctx.gameState._.state === 'init' || ctx.gameState._.state === 'finish') {
                        if (
                            location.pathname !== '/' &&
                            location.pathname !== '/rules/' &&
                            location.pathname !== '/game/'
                        ) {
                            deleteCookie('quiz_sended')
                            deleteCookie('player_id')
                            navigate('/')
                        }
                        if (ctx.gameState._.state === 'finish') {
                            if (location.pathname === '/game/') {
                                navigate('/')
                            }
                        }
                    }
                }
            }
        }
        if (ctx.group._ === 'player' && !cookie) {
            if (ctx.gameState._.state === 'end') {
                if (location.pathname === '/game/') {
                    if(getCookie('quiz_sended')){
                        navigate('/game/result/')
                    }
                }
            }
            if (ctx.gameState._.state === 'finish') {
                if (location.pathname === '/game/' || location.pathname === '/rules/') {
                    navigate('/')
                }
            }
        }
    }, [ctx])

    return (
        <GameStateContext.Provider value={ctx}>
            {children}
        </GameStateContext.Provider>
    );
}