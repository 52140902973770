import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Card } from "../../../components/Card";
import { Title } from "../../../components/Title";
import { Tosser } from "../../../components/Tosser";
import { FireBaseContext } from "../../../context/firebaseContext";
import { GameStateContext } from "../../../context/gameStateContext";
import { randomizer } from "../../../Helper";
import { getGameState, setPlayersDraw, getPlayersList, setGamePlay } from "../../../hooks/apiHooks";

export function GameDraw() {
    const navigate = useNavigate();

    const ctx = useContext(GameStateContext);
    const fbCtx = useContext(FireBaseContext);

    const [isBtnDisabled, setBtnDisabled] = useState(false);
    const [isChosen, setChosen] = useState(false);
    const [isEnded, setEnded] = useState(false);

    useEffect(() => {
        getPlayersList(ctx.playersList.set)
    }, [])


    // useEffect(() => {
    //     getGameState(ctx.gameState.set);
    // }, [fbCtx]);

    useEffect(() => {
        if(!ctx.gameState._.state){
            return
        }
        if (ctx.gameState._.state === 'draw' || ctx.gameState._.state === 'start') {
            randomizer(ctx.gameState._.player_id, ctx.playersList._, setChosen, setBtnDisabled, setEnded);
        }
        // if (ctx.gameState._.state === 'play') {
        //     navigate('/game/choice/')
        // }
    }, [ctx.gameState])


    function sendDraw() {
        setPlayersDraw();
    }

    function restart() {
        setEnded(false);
        setChosen(false);
        setBtnDisabled(false);
    }

    function handleClick() {
        setGamePlay()
    }

    // console.log(isEnded, isEnded)

    return (
        <Card type={'left'}>
            <Title margin={32}>
                Жеребьевка
            </Title>
            {ctx.playersList._ &&
                <Tosser
                    players={ctx.playersList._}
                    handleClick={handleClick}
                    isEnded={isEnded}
                    isChosen={isChosen}
                    restart={restart}
                    isBtnDisabled={isBtnDisabled}
                    sendDraw={sendDraw}
                />
            }
        </Card>
    );
}