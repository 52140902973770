import React from "react";

import { Bars } from "../Bars";
import { Item } from "../Bars/Item";
import { Questions } from "./Questions";

import styles from './choice.module.scss';

export function Choice({ list, onChoose, canChoose }) {
    return (
        <div className={styles.wrapper}>
            <Bars direction={'column'} margin={24}>
                {list.map((item, index) =>
                    <Item key={index} id={index} isDisabled={true}>
                        {item.theme}
                    </Item>
                )}
            </Bars>
            <Questions themes={list} onChoose={onChoose} canChoose={canChoose} />
        </div>
    )
}