import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { QuizForm } from "../../../components/QuizForm";
import { FireBaseContext } from "../../../context/firebaseContext";
import { GameStateContext } from "../../../context/gameStateContext";
import { deleteCookie, getCookie, setCookie } from "../../../Helper";
import { setApiPost } from "../../../hooks/apiHooks";

export function GameQuiz() {
    const ctx = useContext(GameStateContext);
    const fbCtx = useContext(FireBaseContext);
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            let isQuizSended = getCookie('quiz_sended') === 'true' ? true : false
            if (ctx.group._ !== 'moderator') {
                if (isQuizSended) {
                    navigate('/')
                }
            }
        }, 200)
    }, [fbCtx])

    function onSubmit(id, before, after) {
        let data = {
            player_id: id,
            grade_before: before,
            grade_after: after
        }
        // debugger
        setApiPost('/polls/answer/', data, (res) => {
            console.log(res)
            // if(res.data.status === 'success'){
            if (ctx.group._ !== 'moderator') {
                setCookie('quiz_sended', true)
                let isQuizSended = getCookie('quiz_sended') === 'true' ? true : false
                if (isQuizSended) {
                    deleteCookie('player_id')
                    setTimeout(() => {
                        navigate('/game/result/')
                    }, 3000)
                }
            }

            // }
        })
    }
    return (
        <QuizForm onSubmit={onSubmit} />
    );
}