import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Card } from "../../components/Card";
import { Title } from "../../components/Title";
import { Text } from "../../components/Text";
import { Button } from "../../components/Button";
import { GameStateContext } from "../../context/gameStateContext";
import { deleteCookie } from "../../Helper";

export function Main() {
    const navigate = useNavigate();
    const ctx = useContext(GameStateContext);

    useEffect(() => {
        if(ctx.gameState._.state === 'finish'){
            deleteCookie('quiz_ended')
            deleteCookie('player_id')
        }
    }, [])

    return (
        <Card type={"left"}>
            <Title margin={32}>
                Экоревизорро!
            </Title>
            <Text size={'p1'} weight={'semibold'} color={'black'} margin={32}>
                &laquo;Зеленая&raquo; повестка в&nbsp;наши дни становится всё более актуальной. В&nbsp;основе экологически правильного поведения лежит способность людей распознавать ситуации, требующие применения экологических знаний.
            </Text>
            <Text size={'p2'} weight={'semibold'} color={'gray'} margin={32}>
                Проверь свой уровень экологической грамотности!
            </Text>
            {ctx.gameState._.state !== 'finish' ?
                <Button onClick={() => navigate('/rules/')}>
                    Узнать правила игры
                </Button>
                :
                <Text size={'p2'} weight={'semibold'} color={'gray'} margin={32}>
                    Нет активной игры
                </Text>
            }
        </Card>
    );
}