import React, { useState, useContext, useEffect } from "react";


import { Bars } from "../Bars";
import { Item } from "../Bars/Item";
import { Button } from "../Button";
import { Text } from "../Text";
import { BackButton } from '../BackButton';

import styles from './tosser.module.scss';
import { GameStateContext } from "../../context/gameStateContext";

let currentTeam = [];

export function Tosser(props) {
    const ctx = useContext(GameStateContext)
    return (
        <div className={styles.wrapper}>
            {props.isEnded &&
                <Text size={'p1'} margin={32}>
                    Сейчас ходит
                </Text>
            }

            <Bars direction="row" margin={20}>
                {props.isEnded ?
                    props.players.map(team => {
                        if (team.id === props.isChosen) {
                            return (
                                <Item key={team.id} id={team.id} isDisabled={true} isChosen={props.isChosen === team.id}>
                                    {team.name}
                                </Item>
                            )
                        }
                    })
                    : props.players.map(team =>
                        <Item key={team.id} id={team.id} isDisabled={true} isChosen={props.isChosen === team.id}>
                            {team.name}
                        </Item>
                    )}
            </Bars>

            {ctx.group._ === 'moderator' && (

                props.isEnded ?
                    <>
                        {/* <BackButton onClick={props.restart} /> */}
                        <Button onClick={props.handleClick}>
                            Далее
                        </Button>
                    </>
                    :
                    <Button isDisabled={props.isBtnDisabled} onClick={props.sendDraw}>
                        Начать
                    </Button>
            )
            }
            {/* {props.isEnded ?
                (
                    <>
                        <div className={styles.result}>
                            <Text size={'p1'} margin={32}>
                                Сейчас ходит
                            </Text>
                            <Bars direction="row" margin={20}>
                                {props.currentTeam.map(team =>
                                    <Item key={team.id} id={team.id} isDisabled={true} isChosen={false}>
                                        {team.name}
                                    </Item>
                                )}
                            </Bars>
                        </div>
                        {ctx.group._ === 'moderator' && (
                            <>
                                <BackButton onClick={props.restart} />
                                <Button onClick={props.handleClick}>
                                    Далее
                                </Button>
                            </>
                        )
                        }
                    </>
                ) : (
                    <div className={styles.wrapper}>
                        <Bars direction="row" margin={20}>
                            {props.players.map(team =>
                                <Item key={team.id} isDisabled={true} isChosen={props.isChosen === team.id}>
                                    {team.name}
                                </Item>
                            )}
                        </Bars>
                        {ctx.group._ === 'moderator' && (
                            <Button isDisabled={props.isBtnDisabled} onClick={props.sendDraw}>
                                Начать
                            </Button>
                        )
                        }
                    </div>
                )
            } */}
        </div>
    );
}