import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { Text } from "../Text";
import { Title } from "../Title";

import styles from './timer.module.scss';

export function Timer_FB({ seconds, result = () => { }, fullHeight = false, isAnswered = false }) {
    const [isEnded, setEnded] = useState(false)
    const [isEndStop, setEndStop] = useState(false)
    const classes = classNames(
        styles.outer,
        styles['question'],
        { [styles.height]: fullHeight }
    );

    function _result() {
        if (!isEndStop) {
            // console.log("RESULT MAAFAKA")
            result()
            setEnded(true)
        }
    }

    const time = {
        minutes: Math.floor(seconds / 60).toString().padStart(2, '0'),
        seconds: (seconds % 60).toString().padStart(2, '0')
    }

    return (
        <div className={classes}>
            <div className={styles.inner}>
                <Text size={'p1'} weight={'semibold'} color={'black'} margin={24}>
                    Время на выбор ответа
                </Text>
                {
                    time.seconds !== null && (
                        <Title As={'h2'} weight={'bold'} color={'orange'}>
                            {time.minutes}:{time.seconds}
                        </Title>
                    )
                }
            </div>
        </div>
    );
}