import React, { useState, useEffect } from "react";

import { Text } from "../Text";
import { Image } from "../Image";
import { Bars } from "../Bars";
import { Item } from "../Bars/Item";
import { Button } from "../Button";
import { Timer } from "../Timer";
import { Title } from "../Title";
import { Result } from "./Result";

import styles from './gamefield.module.scss';
import { Timer_FB } from "../Timer/timer_fb";

export function GameField(props) {
    const [isActive, setActive] = useState(false);
    const [isBtnDisabled, setBtnDisabled] = useState(true);
    const [answer, setAnswer] = useState(null);
    const [isAnswered, setAnswered] = useState(false);
    const [isCorrect, setCorrect] = useState(false);
    const [title, setTitle] = useState('');
    const [isTimerStopped, setTimerStopped] = useState(false)

    const result = () => {
        setTitle(<>Время вышло!<br /> Переход хода</>);
        setAnswered(true);
        setCorrect(false);
        // debuggergi
    }

    useEffect(() => {
        if(props.correctAnswer !== null){
            if (props.isSkipped) {
                setTitle(<>Время вышло!<br /> Переход хода</>);
                setAnswered(true);
                setCorrect(false);
            }
        }
    }, [props.isSkipped])

    useEffect(() => {
        if(props.timer._ === 0){
            result()
        } else {

        }
    }, [props.timer._])


    useEffect(() => {
        setTimerStopped(false)
        setTimeout(() => {
            setActive(true);
        }, 200);

    }, []);
    
    useEffect(() => {
        setTimerStopped(true)
        if (props.correctAnswer !== null) {
            // setTimeout(() => {}, 2000)
            console.log(props.correctAnswer, 'props.correctAnswer')
            if (props.correctAnswer) {
                setTitle(<>Правильный ответ!<br /> Вы зарабатываете {props.question.score} очков</>);
                setCorrect(true);
            } else {
                setTitle(<>Неправильный ответ!<br /> Переход хода</>);
                setCorrect(false);
            }
            setTimeout(() => {
                setAnswered(true)
                //     setAnswered(true)
            }, 2000)
        }
    }, [props.correctAnswer]);

    function choose(item) {
        if (props.isActivePlayer) {
            if (props.correctAnswer === null) {
                setBtnDisabled(false)
                props.setChosenId(item.id);
            }
        }
    }

    function checkAnswer() {
        setTimerStopped(true)
        setBtnDisabled(true)

        setAnswered(true)
        props.checkAnswer(props.chosenId);
    }



    console.log(props.isModerator, props.isActivePlayer)

    return (
        <div className={`${styles.wrapper} ${isActive && styles.active}`}>
            <div className="container">
                <div className={styles.content}>
                    <div className={styles.questionBlock}>
                        <div className={styles.image}>
                            <div className={styles.info}>
                                <div className={styles.theme}>
                                    <Text size={'p1'} color={'white'}>
                                        {props.question.theme}
                                    </Text>
                                </div>
                                <div className={styles.points}>
                                    <Text size={'p1'} color={'white'}>
                                        {props.question.score}
                                    </Text>
                                </div>
                            </div>
                            <Image src={props.question.image} />
                        </div>
                        <div className={styles.text}>
                            <Text size={'p2'} color={'black'} margin={28}>
                                {props.question.description}
                            </Text>
                        </div>
                        <div className={styles.actions}>
                            {!isAnswered ?
                                (
                                    <>
                                        <Bars direction={'row'} margin={24}>
                                            {props.question.answers.map(item => {
                                                let isClicked = item.id === props.chosenId
                                                let isCorrect = item.id === props.chosenId ? props.correctAnswer : null
                                                if (props.correctAnswer !== null) {
                                                    isClicked = false
                                                }
                                                if (item.id === props.chosenId) {
                                                    console.log(props.correctAnswer, isCorrect)
                                                }

                                                return (<Item
                                                    key={item.id}
                                                    id={item.id}
                                                    onClick={(e) => choose(item)}
                                                    isClicked={isClicked}
                                                    isCorrect={isCorrect}
                                                    isHoverable={props.isActivePlayer}
                                                >
                                                    <Text As={'span'} size={'p2'} weight={'semibold'} color={'white'}>
                                                        {item.title}

                                                    </Text>
                                                </Item>
                                                )
                                            }
                                            )}
                                        </Bars>
                                        {props.isActivePlayer &&
                                            <div className={styles.answerBtn}>
                                                <Button isDisabled={isBtnDisabled} onClick={checkAnswer}>
                                                    Ответить
                                                </Button>
                                            </div>
                                        }
                                        {props.isModerator &&
                                            <div className={styles.answerBtn}>

                                                <Button onClick={props.skipAnswer}>
                                                    Пропустить вопрос
                                                </Button>
                                            </div>
                                        }
                                    </>
                                ) : (
                                    <Result correct={isCorrect} onClick={props.handleClick} children={title} isActivePlayer={props.isActivePlayer || props.isModerator} />
                                )
                            }
                        </div>

                    </div>
                    <div className={styles.infoBlock}>

                        {/* {props.timer._} */}
                        {/* <Timer type={'question'} date={props.date} result={() => {
                            // if (!isAnswered) {

                            //     result()
                            // }
                        }} 
                        // isAnswered={isAnswered} 
                        /> */}
                        <Timer_FB 
                            seconds={props.timer._}
                        />
                        <div className={styles.activePlayer}>
                            Отвечает: {props.actPlayer.name}
                        </div>
                        <div className={styles.rating}>
                            <Title As={'h2'} color={'orange'} margin={32} >
                                Рейтинг
                            </Title>
                            <ul className={styles.teams}>
                                {props.teams.map(team => {
                                    return (
                                        <li className={styles.teamsItem}>
                                            <Text size={'p2'} weight={'semibold'} color={'black'}>
                                                {team.name}
                                            </Text>
                                            <Text size={'p2'} weight={'regular'} color={'gray'}>
                                                {team.score}
                                            </Text>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}