import * as React from 'react';
import classNames from 'classnames';

import styles from './text.module.scss';

export function Text({ As = 'p', children, size, color, weight, margin = 0, _for = '' }) {
    const classes = classNames(
        styles[size],
        styles[color],
        styles[weight],
        styles[`margin-${margin}`]
    );

    return (
        <As htmlFor={_for} className={classes}>
            {children}
        </As>
    );
}