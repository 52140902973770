import * as React from 'react';
import classNames from 'classnames';

import styles from './title.module.scss';

export function Title({ As = 'h1', children, weight = 'bold', margin, color = 'titlegreen' }) {
    const classes = classNames(
        styles[As],
        styles[weight],
        styles[`margin-${margin}`],
        styles[color]
    );

    return (
        <As className={classes}>
            {children}
        </As>
    );
}