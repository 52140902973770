import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from './nav.module.scss';

export function Nav() {
    const location = useLocation();

    const getActiveClass = (url) => {
        let _class = '';

        if (location.pathname.includes(url)) {
            _class = 'active';
        }

        return _class;
    }

    return (
        <ul className={styles.list}>
            <li className={styles.item}>
                <NavLink to="/" activeclassname="active">
                    Главная
                </NavLink>
            </li>
            <li className={styles.item}>
                <NavLink to="/game/" className={getActiveClass('/game/')}>
                    Игра
                </NavLink>
            </li>
            <li className={styles.item}>
                <NavLink to="/rules/" activeclassname="active">
                    Правила
                </NavLink>
            </li>
            <li className={styles.item}>
                <NavLink to="/hallofglory/" activeclassname="active">
                    Зал славы
                </NavLink>
            </li>
        </ul>
    );
}