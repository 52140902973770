import * as React from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/btn_arrow.svg';
import { Text } from '../Text';

export function Button({ onClick = () => {}, isDisabled = false, children }) {
    const classes = classNames(
        styles.wrapper,
        { [styles.disabled]: isDisabled }
    );

    return (
        <div className={classes}>
            <button className={styles.left} onClick={onClick}>
                <Text As={'span'} size={'p2'} weight={'semibold'} color={'white'}>
                    {children}
                </Text>
            </button>
            <div className={styles.right}>
                <Arrow />
            </div>
        </div>
    );
}