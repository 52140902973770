import React from "react";
import classNames from "classnames";

import { Text } from "../../Text";

import styles from './item.module.scss';

export function Item({ id, children, onClick = () => { }, isDisabled, isChosen, isCorrect = null, isWrong, isClicked, isHoverable }) {
    const classes = classNames(
        styles.wrapper,
        { [styles.disabled]: isDisabled },
        { [styles.clicked]: isClicked },
        { [styles.chosen]: isChosen },
        { [styles.isHoverable]: isHoverable },
    );
    const correct = isCorrect !== null ? (isCorrect ? styles.correct : styles.wrong) : null
    return (
        <li id={id} className={classes + " " + correct} onClick={onClick}>
            <Text As="span" size={"p1"} weight={"regular"} color={"white"}>
                {children}
            </Text>
        </li>
    );
}