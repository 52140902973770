import React from "react";

import { Text } from "../Text";

import styles from './awaitinglist.module.scss';

export function AwaitingList({ players }) {
    return (
        <ul>
            {players.map(item =>
                <li key={item.id} className={styles.item}>
                    <div className={styles.name}>
                        <Text size={'p2'} color={'black'}>
                            {item.name}
                        </Text>
                    </div>
                    <div className={styles.city}>
                        <Text size={'p2'} color={'gray'}>
                            {item.city_name}
                        </Text>
                    </div>
                    <div className={styles.school}>
                        <Text size={'p2'} color={'gray'}>
                            {item.school_name}
                        </Text>
                    </div>
                </li>
            )
            }
        </ul>
    );
}