import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './card.module.scss';

export function Card({ children, type }) {
    const [isActive, setActive] = useState(false);

    const outerClasses = classNames(
        styles.outer,
        styles[type],
        { [styles.active]: isActive }
    );

    useEffect(() => {
        setTimeout(() => {
            setActive(true);
        }, 200);
    }, []);

    return (
        <div className={outerClasses}>
            <div className={styles.inner}>
                {children}
            </div>
        </div>
    );
}