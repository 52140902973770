import React from "react";

import { Text } from "../../Text";

import styles from './quizitem.module.scss';

export function QuizItem({ id, name, active, children, handleClick }) {
    return (
        <li key={id} className={`${styles.item} ${active && styles.active}`} data-question={name} onClick={(e) => handleClick(e.target, id)}>
            <Text As={'span'} size={'p1'} color={'white'}>
                {children}
            </Text>
        </li>
    );
}