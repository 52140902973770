import React from "react";
import classNames from "classnames";

import { Title } from "../../Title";

import styles from './result.module.scss';
import { Button } from "../../Button";

export function Result({ correct, onClick, children, isActivePlayer }) {
    const classes = classNames(
        styles.block,
        { [styles.correct]: correct },
        { [styles.wrong]: !correct }
    );

    return (
        <div className={classes}>
            {correct ?
                (
                    <Title As={'h3'} margin={16} color={'darkgreen'}>
                        {children}
                    </Title>
                ) : (
                    <Title As={'h3'} margin={16} color={'red'}>
                        {children}
                    </Title>
                )
            }

            {isActivePlayer &&
                <Button onClick={onClick}>
                    Далее
                </Button>
            }
        </div>
    );
}