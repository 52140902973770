import React, { useContext, useEffect } from "react";

import { Card } from "../../components/Card";
import { Rating } from "../../components/Rating";
import { Title } from "../../components/Title";
import { GameStateContext } from "../../context/gameStateContext";
import { setApiGet } from "../../hooks/apiHooks";

export function HallOfGlory() {
    const ctx = useContext(GameStateContext)
    useEffect(() => {
        setApiGet('/players/top/', ctx.playersTop.set);
    }, [])

    return (
        <Card type={'center'}>
            <Title margin={48}>
                Зал славы
            </Title>
            <Rating type={'glory'} teams={ctx.playersTop._} />
        </Card>
    );
}