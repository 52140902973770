import React from 'react';
import classNames from 'classnames';

import { Text } from '../Text';

import styles from './input.module.scss';

export function Input({ value, type = 'text', label, name, placeholder, onChange = () => {}, isError }) {
    const classes = classNames(
        styles.item,
        styles[type],
        {[styles.error]: isError}
    );

    const _id = Math.random();

    return (
        type !== 'checkbox' ? (
            <div className={classes}>
                <Text As='label' size={'p3'} weight={'regular'} margin={8} color={'black'}>
                    {label}
                </Text>
                <input value={value} type={type} name={name} placeholder={placeholder} onChange={onChange} />
                {isError &&
                    <div className={styles.mistake}>
                        {isError}
                    </div>
                }
            </div>
        ) : (
            <div className={classes}>
                <input type={type} name={name} id={_id} />
                <Text As='label' _for={_id} size={'p3'} weight={'regular'} margin={8} color={'black'}>
                    {label}
                </Text>
            </div>
        )
        
    );
}