import { useState, useEffect } from "react";
import API from "../API";

export function getGameInfo(setter = () => { }) {
    setApiGet('/games/info/', setter)
}
export function getGameState(setter = () => { }) {
    setApiGet('/games/state/', setter)
}
export function getPlayersList(setter = () => { }) {
    setApiGet('/players/list/', setter)
}
export function getQuestionsList(setter = () => { }) {
    setApiGet('/questions/list/', setter)
}
export function getQuestionsInfo(setter = () => { }) {
    setApiGet('/questions/info/', setter)
}
export function getServerTime(setter = () => { }) {
    setApiGet('/server/time/', setter)
}

export function setPlayersDraw(setter = () => { }) {
    setApiGet('/players/draw/', setter)
}
export function setGameStart(setter = () => { }) {
    setApiGet('/games/start/', setter)
}
export function setGamePlay(setter = () => { }) {
    setApiGet('/games/play/', setter)
}
export function setQuestionResult(setter = () => { }) {
    setApiGet('/questions/result/', setter)
}


export function setApiGet(url, cb = () => { }, cbErr = console.log) {
    API.get(url).then(res => {
        console.log(res, url, 'REQUEST GET')
        if (res.data.status === 'success') {
            cb(res.data.payload.data)
        } else {
            cbErr(res)
        }
    }).catch(cbErr)
}

export function setApiPost(url, data = {}, cb =() => {}, cbErr = console.log){
    API.post(url, data).then(res => {
        console.log(res, url, 'REQUEST POST')
        if (res.data.status === 'success') {
            cb(res.data.payload.data)
        } else {
            cbErr(res)
        }
    }).catch(cbErr)
}