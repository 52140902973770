import { useContext, useEffect } from "react"
import { GameStateContext } from "../context/gameStateContext"
import { getGameInfo } from "../hooks/apiHooks"


export function API_Setter() {

    const ctx = useContext(GameStateContext)
    useEffect(() => {
        getGameInfo(ctx.gameInfo.set)
    }, [])

    return (
        <></>
    )
}